import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'normalize.css';
import './style/base.scss';
import 'amfe-flexible';
import BuriedPoint from './utils/buried-point';
import './rem.js';
// import Vconsole from 'vconsole';

// const vConsole = new Vconsole();

const app = createApp(App);
app.config.globalProperties.$buried_point = BuriedPoint;
// 渲染
app
  .use(store)
  .use(router)
  .mount('#app');
