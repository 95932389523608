// 接口校验
interface ImageCodeData {
  base64Image?: string;
  randomId?: string;
}
interface State {
  globalLoading: number; // 全局loading效果
  showImgCode: boolean;
  addressList: Array<any>; // 地址列表
  // eslint-disable-next-line @typescript-eslint/ban-types
  certification: object;
  imgCodeData: ImageCodeData;
}
// 修改数据
const mutations = {
  // 设置loading
  SET_LOADING: (state: State, status: number) => {
    if (status === 0) {
      state.globalLoading = 0;
      return;
    }
    state.globalLoading = status ? state.globalLoading + 1 : state.globalLoading - 1;
  },
  // 设置是否显示图形验证码
  SET_IMGCODE: (state: State, status: boolean) => {
    state.showImgCode = status;
  },
  // 获取地址列表
  SET_ADDRESS: (state: State, status: Array<any>) => {
    state.addressList = status;
  },
  // 获取认证信息
  SET_CEREIFICATION: (state: State, status: any) => {
    const newData = status;
    const tel = newData.mobile || '';
    const name = newData.realName || '';
    newData.desenMobile = `${tel.slice(0, 3)}****${tel.slice(-4)}`;
    newData.desenName = name.length === 2 ? `*${name.slice(-1)}` : `${name.slice(0, 1)}*${name.slice(-1)}`;
    state.certification = newData;
  },
  // 获取登录图片信息
  SET_IMGCODEDATA: (state: State, status: any) => {
    state.imgCodeData = status;
  },
};

const ship = '';

export {
  mutations,
  ship,
};
