/**
 * 埋点
 */
interface Window {
  aplus_queue: any
}
declare const window: Window & { aplus_queue: any };
class BuriedPoint {
  // 埋点
  trackEvent(eventCode: string, eventParams: any) {
    window.aplus_queue.push({
      action: 'aplus.record',
      arguments: [eventCode, 'CLK', eventParams],
    });
  }

  // ali-首页-浏览首页
  view_homePage(params: any) {
    this.trackEvent('view_homePage', params);
  }

  // ali-首页-点击广告位
  click_homeAd(params: any) {
    this.trackEvent('click_homeAd', params);
  }

  // ali-首页-点击商品
  click_homeGoods(params: any) {
    this.trackEvent('click_homeGoods', params);
  }

  // ali-分类页-点击分类
  click_category(params: any) {
    this.trackEvent('click_category', params);
  }

  // ali-分类页-点击品牌
  click_brand(params: any) {
    this.trackEvent('click_brand', params);
  }

  // ali-分类页-点击商品
  click_categoryGoods(params: any) {
    this.trackEvent('click_categoryGoods', params);
  }

  // ali-商品详情页-浏览商品
  view_goodsDetail(params: any) {
    this.trackEvent('view_goodsDetail', params);
  }

  // ali-商品详情页-点击广告位
  click_detailAd(params: any) {
    this.trackEvent('click_detailAd', params);
  }

  // ali-商品详情页-显示优惠浮层
  show_coupon(params: any) {
    this.trackEvent('show_coupon', params);
  }

  // ali-商品详情页-领取优惠券
  get_coupon(params: any) {
    this.trackEvent('get_coupon', params);
  }

  // ali-商品详情页-点击选择
  click_select(params: any) {
    this.trackEvent('click_select', params);
  }

  // ali-商品详情页-点击商品详情
  click_detail(params: any) {
    this.trackEvent('click_detail', params);
  }

  // ali-商品详情页-点击租赁必读
  click_rentNotice(params: any) {
    this.trackEvent('click_rentNotice', params);
  }

  // ali-商品详情页-点击首页
  click_home(params: any) {
    this.trackEvent('click_home', params);
  }

  // ali-商品详情页-显示客服弹窗
  show_onlineService(params: any) {
    this.trackEvent('show_onlineService', params);
  }

  // ali-商品详情页-点击商家服务电话
  click_phone(params: any) {
    this.trackEvent('click_phone', params);
  }

  // ali-商品详情页-点击免押租
  click_rent(params: any) {
    this.trackEvent('click_rent', params);
  }

  // ali-商品详情页-点击确认下单
  click_confirmOrder(params: any) {
    this.trackEvent('click_confirmOrder', params);
  }

  // ali-确认订单页-点击选择收货地址
  click_address(params: any) {
    this.trackEvent('click_address', params);
  }

  // ali-确认订单页-显示优惠明细浮层
  show_couponDetail(params: any) {
    this.trackEvent('show_couponDetail', params);
  }

  // ali-确认订单页-勾选阅读并同意协议
  selecte_contract(params: any) {
    this.trackEvent('selecte_contract', params);
  }

  // ali-确认订单页-点击去免押
  go_rent(params: any) {
    this.trackEvent('go_rent', params);
  }

  // ali-芝麻信用服务-同意协议并使用
  agree_contract(params: any) {
    this.trackEvent('agree_contract', params);
  }

  // ali-实名认证页-点击确定
  click_confirm(params: any) {
    this.trackEvent('click_confirm', params);
  }

  // ali-确认下单页-点击加速审核
  click_audit(params: any) {
    this.trackEvent('click_audit', params);
  }

  // ali-确认下单页-点击查看订单
  click_viewOrder(params: any) {
    this.trackEvent('click_viewOrder', params);
  }

  // ali-确认下单页-点击广告位
  click_orderAd(params: any) {
    this.trackEvent('click_orderAd', params);
  }

  // ali-确认下单页-点击商品
  click_goods(params: any) {
    this.trackEvent('click_goods', params);
  }

  // ali-确认下单页-点击返回首页
  click_backHome(params: any) {
    this.trackEvent('click_backHome', params);
  }

  // ali-意见反馈-点击提交
  click_submitOpinion(params: any) {
    this.trackEvent('click_submitOpinion', params);
  }

  // ali-实名认证页-点击提交
  click_submitName(params: any) {
    this.trackEvent('click_submitName', params);
  }

  // ali-我的订单-浏览订单列表
  view_myOrder(params: any) {
    this.trackEvent('view_myOrder', params);
  }

  // ali-订单详情-浏览订单详情
  view_orderDetail(params: any) {
    this.trackEvent('view_orderDetail', params);
  }

  // ali-订单详情-点击取消订单
  click_cancelOrder(params: any) {
    this.trackEvent('click_cancelOrder', params);
  }

  // ali-我的-点击联系客服
  click_contactService(params: any) {
    this.trackEvent('click_contactService', params);
  }

  // ali-订单详情-点击联系客服
  click_orderService(params: any) {
    this.trackEvent('click_orderService', params);
  }

  // ali-订单详情-点击租赁服务协议
  click_contract(params: any) {
    this.trackEvent('click_contract', params);
  }

  // ali-订单详情-显示是否放弃免押弹窗
  show_abandonRent(params: any) {
    this.trackEvent('show_abandonRent', params);
  }

  // ali-订单详情-点击去催审
  click_goAudit(params: any) {
    this.trackEvent('click_goAudit', params);
  }

  // ali-订单详情-点击残忍取消
  click_cruelCancel(params: any) {
    this.trackEvent('click_cruelCancel', params);
  }

  // ali-订单详情-点击去免押
  click_goRent(params: any) {
    this.trackEvent('click_goRent', params);
  }

  // ali-订单详情-点击广告位
  click_ad(params: any) {
    this.trackEvent('click_ad', params);
  }

  // ali-我的页面-点击广告位
  click_myAd(params: any) {
    this.trackEvent('click_myAd', params);
  }

  // ali-订单详情-点击查看账单
  click_checkBill(params: any) {
    this.trackEvent('click_checkBill', params);
  }

  // ali-我的-点击我的订单
  click_myOrder(params: any) {
    this.trackEvent('click_myOrder', params);
  }

  // ali-我的-点击待冻结
  click_beFrozen(params: any) {
    this.trackEvent('click_beFrozen', params);
  }

  // ali-我的-点击待发货
  click_beSend(params: any) {
    this.trackEvent('click_beSend', params);
  }

  // ali-我的-点击待收货
  click_beTake(params: any) {
    this.trackEvent('click_beTake', params);
  }

  // ali-我的-点击退货/售后
  click_beBack(params: any) {
    this.trackEvent('click_beBack', params);
  }

  // ali-我的-点击优惠券
  click_coupon(params: any) {
    this.trackEvent('click_coupon', params);
  }

  // ali-我的-点击我的账单
  click_myBill(params: any) {
    this.trackEvent('click_myBill', params);
  }

  // ali-我的-点击我要买断
  click_buyOut(params: any) {
    this.trackEvent('click_buyOut', params);
  }

  // ali-我的-点击实名认证
  click_realName(params: any) {
    this.trackEvent('click_realName', params);
  }

  // ali-我的-点击租机流程
  click_rentFlow(params: any) {
    this.trackEvent('click_rentFlow', params);
  }

  // ali-我的-点击常见问题
  click_question(params: any) {
    this.trackEvent('click_question', params);
  }

  // ali-我的-点击供应商入驻
  click_supplierIn(params: any) {
    this.trackEvent('click_supplierIn', params);
  }

  // ali-我的-点击意见反馈
  click_feedBack(params: any) {
    this.trackEvent('click_feedBack', params);
  }

  // ali-我的-点击设置
  click_set(params: any) {
    this.trackEvent('click_set', params);
  }

  // ali-我的优惠券-点击未使用tab
  click_noUse(params: any) {
    this.trackEvent('click_noUse', params);
  }

  // ali-我的优惠券-点击已使用tab
  click_used(params: any) {
    this.trackEvent('click_used', params);
  }

  // ali-我的优惠券-点击已过期tab
  click_expired(params: any) {
    this.trackEvent('click_expired', params);
  }

  // ali-我的优惠券-点击去使用
  click_goUse(params: any) {
    this.trackEvent('click_goUse', params);
  }

  // ali-我的-点击关于我们
  click_aboutus(params: any) {
    this.trackEvent('click_aboutus', params);
  }

  // ali-我的-关于我们-点击客服电话
  click_servicehotline(params: any) {
    this.trackEvent('click_servicehotline', params);
  }

  // ali-我的-点击紧急联系人
  click_emergencycontact(params: any) {
    this.trackEvent('click_emergencycontact', params);
  }

  // ali-我的-紧急联系人-点击提交
  click_emergencycontactSubmit(params: any) {
    this.trackEvent('click_emergencycontactSubmit', params);
  }
}
export default new BuriedPoint();
