import { RouteRecordRaw } from 'vue-router';

import BindCard from './bindCard';

const NoFind = () => import('../views/nofind/NoFind.vue');

const routes: Array<RouteRecordRaw> = [
  ...BindCard,
  {
    path: '/:pathMatch(.*)',
    name: '404',
    component: NoFind,
  },
];
export default routes;
