// state存储
const state = {
  globalLoading: 0,
  showImgCode: false,
  addressList: [],
  certification: {},
  imgCodeData: {},
};
const ship = '';

export {
  state,
  ship,
};
