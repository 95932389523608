import {
  createRouter, createWebHashHistory, Router,
} from 'vue-router';
import routes from './routers';

interface Window {
  aplus_queue: any
}
declare const window: Window & { aplus_queue: any };
const router: Router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  window.aplus_queue.push({
    action: 'aplus.sendPV',
    arguments: [{ is_auto: false }],
  });
  const { name } = to;
  document.title = String(name);
  next();
});

export default router;
